import React from 'react';

export default function ASide() {
	return (
		<aside className="column">
			<div className="container">
			
			</div>
		</aside>
	);
}
