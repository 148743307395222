import React from 'react';
import Body from './Body';

import Header from './Header';

function App() {
	return (
		<>
			<Header />
			<Body />
		</>
	);
}

export default App;
